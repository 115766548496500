import z from 'zod'

export const actionNewsletterSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('004'),
  eventCategory: z.literal('newsletter'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('subscribe'),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const actionFilterlistingSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('008'),
  eventCategory: z.literal('filter_listing'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const actionFinalizeOrderButtonSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('009'),
  eventCategory: z.literal('finalize_order'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.number(),
  eventElement02: z.string(),
  eventElement03: z.string(),
})

export const actionInternalSearchSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('013'),
  eventCategory: z.literal('internal_search'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const actionReserveInStoreSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('015'),
  eventCategory: z.literal('reserve_in_store'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.string(),
})

export const actionChooseCountrySchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('017'),
  eventCategory: z.literal('choose_country'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string().optional(),
  eventElement01: z.string().optional(),
  eventElement02: z.enum(['done', 'start']),
  eventElement03: z.literal('-'),
})

export const actionContanctUsSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('018'),
  eventCategory: z.literal('contact_us'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.literal('-'),
  eventElement01: z.literal('-'),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const actionNewletterUnsubscribeSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('019'),
  eventCategory: z.literal('newsletter_unsubscribe'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})

export const actionContactCheckoutSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('024'),
  eventCategory: z.literal('contact_checkout'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.literal('-'),
  eventElement03: z.literal('-'),
})
export const actionThankYouButtonSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('025'),
  eventCategory: z.literal('thank_you_page'),
  eventAction: z.literal('click_cta'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.number(),
})

export const actionFeedbackSchema = z.object({
  event: z.literal('GAevent'),
  eventID: z.literal('027'),
  eventCategory: z.literal('thank_you_page'),
  eventAction: z.literal('feedback'),
  eventLabel: z.string(),
  eventElement01: z.string(),
  eventElement02: z.string(),
  eventElement03: z.number(),
})
