import snakeCase from 'lodash/snakeCase'
import {
  actionNewsletterSchema,
  actionFilterlistingSchema,
  actionFinalizeOrderButtonSchema,
  actionInternalSearchSchema,
  actionReserveInStoreSchema,
  actionChooseCountrySchema,
  actionContanctUsSchema,
  actionNewletterUnsubscribeSchema,
  actionContactCheckoutSchema,
  actionThankYouButtonSchema,
  actionFeedbackSchema,
} from '@integration-layer/schemas/GAEvents/actionEvents'

export const useGAActionEvents = () => {
  const debug = !!useRuntimeConfig().public.ga4.debug

  const dispatchActionNewsletterEvent = (brand: string) => {
    useGASendEvent(
      actionNewsletterSchema,
      {
        event: 'GAevent',
        eventID: '004',
        eventCategory: 'newsletter',
        eventAction: 'click_cta',
        eventLabel: 'subscribe',
        eventElement01: snakeCase(brand) ?? '',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionFilterlistingEvent = (filter: string, value: string) => {
    useGASendEvent(
      actionFilterlistingSchema,
      {
        event: 'GAevent',
        eventID: '008',
        eventCategory: 'filter_listing',
        eventAction: 'click_cta',
        eventLabel: filter ?? '',
        eventElement01: value ?? '',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionFinalizeOrderButtonEvent = (
    currency: string,
    value: number,
    shipping: string,
    payment: string
  ) => {
    useGASendEvent(
      actionFinalizeOrderButtonSchema,
      {
        event: 'GAevent',
        eventID: '009',
        eventCategory: 'finalize_order',
        eventAction: 'click_cta',
        eventLabel: currency ?? '',
        eventElement01: value ?? 0,
        eventElement02: shipping ?? '',
        eventElement03: payment ?? '',
      },
      { debug }
    )
  }

  const dispatchActionInternalSearchEvent = (query: string) => {
    useGASendEvent(
      actionInternalSearchSchema,
      {
        event: 'GAevent',
        eventID: '013',
        eventCategory: 'internal_search',
        eventAction: 'click_cta',
        eventLabel: query ?? '',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionReserveInStoreEvent = (
    itemName: string,
    itemSize: string,
    itemColor: string,
    itemID: string
  ) => {
    useGASendEvent(
      actionReserveInStoreSchema,
      {
        event: 'GAevent',
        eventID: '015',
        eventCategory: 'reserve_in_store',
        eventAction: 'click_cta',
        eventLabel: itemName ?? '',
        eventElement01: itemSize ?? '',
        eventElement02: itemColor ?? '',
        eventElement03: itemID ?? '',
      },
      { debug }
    )
  }

  const dispatchActionChooseCountryEvent = (
    country: string,
    language: string,
    action: 'done' | 'start'
  ) => {
    useGASendEvent(
      actionChooseCountrySchema,
      {
        event: 'GAevent',
        eventID: '017',
        eventCategory: 'choose_country',
        eventAction: 'click_cta',
        eventLabel: country ?? '',
        eventElement01: language ?? '',
        eventElement02: action,
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionContactUsEvent = () => {
    useGASendEvent(
      actionContanctUsSchema,
      {
        event: 'GAevent',
        eventID: '018',
        eventCategory: 'contact_us',
        eventAction: 'click_cta',
        eventLabel: '-',
        eventElement01: '-',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionNewletterUnsubscribeEvent = (
    step: string,
    brand: string
  ) => {
    useGASendEvent(
      actionNewletterUnsubscribeSchema,
      {
        event: 'GAevent',
        eventID: '019',
        eventCategory: 'newsletter_unsubscribe',
        eventAction: 'click_cta',
        eventLabel: step ?? '',
        eventElement01: snakeCase(brand) ?? '',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionContactCheckoutEvent = (
    step: string,
    channel: string
  ) => {
    useGASendEvent(
      actionContactCheckoutSchema,
      {
        event: 'GAevent',
        eventID: '024',
        eventCategory: 'contact_checkout',
        eventAction: 'click_cta',
        eventLabel: step ?? '',
        eventElement01: channel ?? '',
        eventElement02: '-',
        eventElement03: '-',
      },
      { debug }
    )
  }

  const dispatchActionThankYouButtonEvent = (
    action: string,
    transactionID: string,
    currency: string,
    transactionValue: number
  ) => {
    useGASendEvent(
      actionThankYouButtonSchema,
      {
        event: 'GAevent',
        eventID: '025',
        eventCategory: 'thank_you_page',
        eventAction: 'click_cta',
        eventLabel: action ?? '',
        eventElement01: transactionID ?? '',
        eventElement02: currency ?? '',
        eventElement03: transactionValue ?? '',
      },
      { debug }
    )
  }

  const dispatchActionFeedbackEvent = (
    rate: string,
    orderID: string,
    currency: string,
    orderValue: number
  ) => {
    useGASendEvent(
      actionFeedbackSchema,
      {
        event: 'GAevent',
        eventID: '027',
        eventCategory: 'thank_you_page',
        eventAction: 'feedback',
        eventLabel: rate ?? '',
        eventElement01: orderID ?? '',
        eventElement02: currency ?? '',
        eventElement03: orderValue ?? '',
      },
      { debug }
    )
  }

  return {
    dispatchActionNewsletterEvent,
    dispatchActionFilterlistingEvent,
    dispatchActionFinalizeOrderButtonEvent,
    dispatchActionInternalSearchEvent,
    dispatchActionReserveInStoreEvent,
    dispatchActionChooseCountryEvent,
    dispatchActionContactUsEvent,
    dispatchActionNewletterUnsubscribeEvent,
    dispatchActionContactCheckoutEvent,
    dispatchActionThankYouButtonEvent,
    dispatchActionFeedbackEvent,
  }
}
